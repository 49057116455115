import styled, { css } from 'styled-components'

type ImageItemStyledProps = {
  hidden?: boolean
}

export const ImageItemStyled = styled('div')<ImageItemStyledProps>(
  ({ theme, hidden }) => css`
    cursor: pointer;
    position: relative;
    display: flex;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .img-overlay {
      ${theme.mixins.engulfAbsolute}
      ${theme.mixins.flexCenter}

      color: ${theme.colors.white};
      transition: all 0.2s;
      opacity: 0;
    }

    .img-overlay.always-visible {
      background: ${theme.mixins.opacity(theme.colors.black, 0.3)};
      opacity: 1;
    }

    &:hover {
      .img-overlay {
        background: ${theme.mixins.opacity(theme.colors.black, 0.4)};
        opacity: 1;
      }
    }

    ${hidden
      ? css`
          position: fixed;
          opacity: 0;
          pointer-events: none;

          img {
            width: 0;
            height: 0;
          }
        `
      : ''}
  `
)
