import styled, { css } from 'styled-components'
import { BgType } from '@pages/Customer/types'

type Props = Readonly<{
  bgType: BgType
  transparent?: boolean
}>

export const HeaderStyled = styled.div<Props>(
  ({ theme, bgType, transparent }) => css`
    z-index: 9;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.2s;
    color: ${theme.colors.black};
    pointer-events: none;
    display: flex;
    flex-direction: column;

    .link-item {
      ${theme.mixins.flexCenter}
      text-decoration: none;
      color: ${theme.colors.black};
      font-size: ${theme.global.typography.size['size-18']};
      padding: ${theme.spacing(0, 2.5)};
      position: relative;
      border: none;
      background: transparent;
      font-family: inherit;
      white-space: nowrap;
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        //background: currentColor;
        height: 0;
        opacity: 0;
        transition: all 0.3s;
        left: 0;
        right: 0;
        bottom: 0;
      }

      &:hover:after {
        opacity: 0.25;
        height: 2px;
      }

      &.active:after {
        opacity: 1;
        height: 2px;
      }
    }

    .header-bar {
      z-index: 2;
      pointer-events: all;
      display: flex;
      align-items: center;
      box-shadow: 0 0 20px 0 ${theme.mixins.opacity(theme.colors.black, bgType === 'light' ? 0.12 : 0)};
      background-color: ${bgType === 'light' ? theme.colors.white : theme.colors.grey200};
      opacity: ${transparent ? 0.75 : 1};

      transition: all 0.2s;

      height: ${theme.spacing(10)};
      padding: ${theme.spacing(0, 7)};

      ${theme.breakpoints.down('lg')} {
        padding: ${theme.spacing(0, 3)};
      }

      .viimsi-logo {
        font-size: 2.5em;
      }

      .links {
        ${theme.mixins.flexCenter}
        height: 100%;

        ${theme.breakpoints.down('md')} {
          display: none;
        }

        .link-item {
          height: 100%;
        }
      }

      /*
      .language-dropdown {
        ${theme.breakpoints.down('md')} {
          display: none;
        }
      }
      */

      .menu-btn {
        border: none;
        background: transparent;
        color: ${theme.colors.black};
        cursor: pointer;

        /*
        ${theme.breakpoints.up('md')} {
          display: none;
        }
        */
      }
    }

    .mobile-menu {
      pointer-events: all;
      background: ${theme.colors.white};
      display: flex;
      flex-direction: column;
      align-items: center;
      //justify-content: space-around;
      //flex: 1;
      transform: translateY(-120%);
      opacity: 0;
      transition: all 0.5s;

      .link-item {
        padding: ${theme.spacing(2)};
      }

      &.open {
        opacity: 1;
        transform: translateY(0);
      }
    }
  `
)
