import 'lightgallery/css/lg-thumbnail.css'
import 'lightgallery/css/lg-zoom.css'
import 'lightgallery/css/lightgallery.css'
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import LightGallery, { LightGalleryProps } from 'lightgallery/react'
import { lightGalleryLicenceKey } from '@constants/configuration'
import { imageItemClassName } from './ImageItem'

type ImageGalleryProps = LightGalleryProps

export const ImageGallery = ({ children, ...props }: ImageGalleryProps): React.ReactElement | null => {
  return (
    <LightGallery
      licenseKey={lightGalleryLicenceKey}
      selector={`.${imageItemClassName}`}
      plugins={[lgThumbnail, lgZoom]}
      {...props}
    >
      {children}
    </LightGallery>
  )
}
