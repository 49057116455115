import * as React from 'react'

// TODO: These functions must be removed and replaced with CSS approach (add content to DOM node)

export const toSquareMeters = (value: number | string): React.ReactNode => {
  return (
    <>
      {`${value} m`}
      <sup>2</sup>
    </>
  )
}

export const toRequiredFieldLabel = (label: string): string => `${label} *`
