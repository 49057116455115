import * as React from 'react'
import classNames from 'classnames'
import { ZoomIn } from '@mui/icons-material'
import { ImageItemStyled } from './ImageItem.styles'

type ImageItemProps = {
  src: string
  hidden?: boolean
  className?: string
  overlayAlwaysVisible?: boolean
}

export const imageItemClassName = 'img-item'

export const ImageItem: React.FC<ImageItemProps> = ({ src, className, hidden, overlayAlwaysVisible }) => (
  <ImageItemStyled className={className ?? imageItemClassName} hidden={hidden} data-src={src}>
    <img src={src} alt="Gallery image" loading="lazy" />
    <div className={classNames('img-overlay', overlayAlwaysVisible ? 'always-visible' : undefined)}>
      <ZoomIn />
    </div>
  </ImageItemStyled>
)
