import { useTranslation } from 'react-i18next'

type FormatFunction = (
  value: number,
  currency: string,
  options?: Omit<Intl.NumberFormatOptions, 'style' | 'currency'>
) => string

export const useCurrencyFormatter = (): FormatFunction => {
  const { i18n } = useTranslation()

  return (value, currency, options) => {
    return Intl.NumberFormat(i18n.language, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      ...options,
      style: 'currency',
      currency
    }).format(value)
  }
}
