import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { To, useLocation, useNavigate } from 'react-router-dom'
import { hasValue } from '@digital-magic/ts-common-utils'
import { externalLinks } from '@constants/externalLinks'
import { routes } from '@constants/routes'
import { useGetLanguage } from '@hooks/useGetLanguage'
import { useScrollPosition } from '@hooks/useScrollPosition'
import classNames from 'classnames'
import { Menu } from '@mui/icons-material'
import { Box } from '@mui/material'
import { LanguageDropdown } from '@controls/LanguageDropdown'
import { BgType } from '@pages/Customer/types'
import { ViimsiLogo } from '@icons/ViimsiLogo'
import { HeaderStyled } from './Header.styles'

const removeLeadingHash = (value: string): string => (value.startsWith('#') ? value.slice(1) : value)

type BasicLink = Readonly<{ label: string }>
type LocalLink = BasicLink & Readonly<{ id: string; options?: ScrollIntoViewOptions }>
type RouteLink = BasicLink & Readonly<{ to: To }>
type ExternalLink = BasicLink & Readonly<{ href: string }>
type LinkItem = LocalLink | RouteLink | ExternalLink

const isLocalLink = (link: LinkItem): link is LocalLink => 'id' in link
const isRouteLink = (link: LinkItem): link is RouteLink => 'to' in link

type Props = {
  bgType: BgType
  autoHide?: boolean
  transparent?: boolean
}

export const Header: React.FC<Props> = ({ bgType, autoHide, transparent }) => {
  const { t } = useTranslation()
  const language = useGetLanguage()
  const scrollPos = useScrollPosition()
  //const theme = useTheme()

  const [menuOpen, setMenuOpen] = React.useState(false)
  const menuVisible = true //useMediaQuery(theme.breakpoints.down('md'))

  const navigate = useNavigate()
  const location = useLocation()

  const linkItems: ReadonlyArray<LinkItem> = [
    { id: 'about', label: t('pages.landing.header.about') },
    { id: 'gallery', label: t('pages.landing.header.gallery') },
    { id: 'location', label: t('pages.landing.header.location') },
    { id: 'timeline', label: t('pages.landing.header.timeline'), options: { block: 'center' } },
    { id: 'houses', label: t('pages.landing.header.houses') },
    {
      label: t('pages.landing.header.tour360'),
      href: externalLinks.tour360
    },
    { id: 'prices', label: t('pages.landing.header.prices') },
    { id: 'contact', label: t('pages.landing.header.contact') },
    {
      label: t('pages.landing.header.clientsDay'),
      href: externalLinks.clientsDay + (language === 'RUS' ? '/den-klienta' : '')
    }
  ]

  const toggleMenu = (): void => {
    setMenuOpen((o) => !o)
  }

  const scrollToView = (id: string, options?: ScrollIntoViewOptions): void => {
    const behavior: ScrollIntoViewOptions['behavior'] = menuVisible ? 'auto' : 'smooth'
    const el = document.getElementById(id)
    if (el) {
      el.scrollIntoView({ behavior, ...options })
    }
  }

  const scrollToViewByHash = (): void => {
    const hashValue = removeLeadingHash(location.hash)
    if (hasValue(hashValue)) {
      const link = linkItems.find((v) => isLocalLink(v) && v.id === hashValue)
      if (hasValue(link) && isLocalLink(link)) {
        scrollToView(link.id, link.options)
      }
    }
  }

  const onLinkClick = (id: string, options?: ScrollIntoViewOptions) => (): void => {
    navigate(`${routes.Customer.Index}#${id}`)
    scrollToView(id, options)
    if (menuOpen) {
      toggleMenu()
    }
  }

  React.useEffect(() => {
    if (!menuVisible) {
      setMenuOpen(false)
    }
  }, [menuVisible])

  React.useEffect(() => {
    setTimeout(scrollToViewByHash, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const offsetYPercentage = -100 + scrollPos / 2
  const opacityValue = 1 + offsetYPercentage / 100

  const linkButtons: React.ReactNode = linkItems.map((item) =>
    isLocalLink(item) ? (
      <button key={item.label} className="link-item" onClick={onLinkClick(item.id, item.options)}>
        {item.label}
      </button>
    ) : isRouteLink(item) ? (
      <button
        key={item.label}
        onClick={() => navigate(item.to)}
        className={classNames('link-item', { active: location.pathname === item.to })}
      >
        {item.label}
      </button>
    ) : (
      <button
        key={item.label}
        className="link-item"
        onClick={() => window.open(item.href, '_blank', 'noopener,noreferrer')}
      >
        {item.label}
      </button>
    )
  )

  const fadeInStyles: React.CSSProperties = {
    transform: `translateY(${offsetYPercentage >= 0 ? 0 : offsetYPercentage}%)`,
    opacity: opacityValue >= 1 ? 1 : opacityValue
  }

  return (
    <HeaderStyled bgType={bgType} transparent={transparent}>
      <div className="header-bar" style={autoHide ? fadeInStyles : undefined}>
        <Box flex={1} display="flex" alignItems="center">
          <ViimsiLogo className="viimsi-logo" />
        </Box>
        {!menuVisible && (
          <Box flex={3} className="links">
            {linkButtons}
          </Box>
        )}
        <Box flex={1} width={100} display="flex" justifyContent="flex-end">
          <LanguageDropdown color="black" />
          <button onClick={toggleMenu} className="menu-btn">
            <Menu />
          </button>
        </Box>
      </div>
      {menuVisible && <div className={classNames('mobile-menu', { open: menuOpen })}>{linkButtons}</div>}
    </HeaderStyled>
  )
}
