import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from '@controls/Link'
import { Text } from '@controls/Text'
import { ContactAvatarStyled } from './ContactAvatar.styles'

export const ContactAvatar: React.FC = () => {
  const { t } = useTranslation()

  return (
    <ContactAvatarStyled>
      <div className="avatar" />
      <div className="contacts">
        <Text className="text-block" size="size-34" color="brown" mt={2}>
          {t('app.main.contacts.representative.name')}
        </Text>
        <Text className="text-block" size="size-18" whiteSpace="pre-wrap" lineHeight="1.75em">
          {t('app.main.contacts.representative.role')}
        </Text>
        <Link
          href={`tel: ${t('app.main.contacts.representative.phone')}`}
          target="_blank"
          rel="noopener"
          className="text-block"
        >
          {t('app.main.contacts.representative.phone')}
        </Link>
        <Link
          href={`mailto: ${t('app.main.contacts.representative.email')}`}
          target="_blank"
          rel="noopener"
          className="text-block"
        >
          {t('app.main.contacts.representative.email')}
        </Link>
      </div>
    </ContactAvatarStyled>
  )
}
