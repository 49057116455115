import { SvgIconProps } from '@mui/material'

export const ViimsiLogo = (props: SvgIconProps): React.ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="2.4824em" height="1em" viewBox="0 0 457.592 184.334" {...props}>
      <g id="Group_6" data-name="Group 6" transform="translate(1438.207 -693.074)">
        <path
          id="Path_27"
          data-name="Path 27"
          d="M-1361.008,693.2l-34.533,77.98h-8.129l-34.538-77.98h8.916l29.855,67.735,30.077-67.735Z"
          transform="translate(0 0.545)"
          fill="currentColor"
        />
        <path
          id="Path_28"
          data-name="Path 28"
          d="M-1382.4,693.2l-48.7,109.962-2.284-13.082,42.628-96.88Z"
          transform="translate(21.388 0.545)"
          fill="currentColor"
        />
        <path
          id="Path_29"
          data-name="Path 29"
          d="M-1361.008,702.906l-34.533,77.98h-8.129l-34.538-77.98h8.916l29.855,67.73,30.077-67.73Z"
          transform="translate(0 43.519)"
          fill="currentColor"
        />
        <path
          id="Path_30"
          data-name="Path 30"
          d="M-1385.762,803.47l-4.873,6.848-47.573-107.412h8.916Z"
          transform="translate(0 43.519)"
          fill="currentColor"
        />
        <path
          id="Path_31"
          data-name="Path 31"
          d="M-1421.906,693.2h8.242v77.98h-8.242Z"
          transform="translate(72.151 0.545)"
          fill="currentColor"
        />
        <path
          id="Path_32"
          data-name="Path 32"
          d="M-1417.225,693.2h8.242v77.98h-8.242Z"
          transform="translate(92.871 0.545)"
          fill="currentColor"
        />
        <path
          id="Path_33"
          data-name="Path 33"
          d="M-1331.225,693.2v77.98h-7.911V708.792l-30.636,52.472h-3.9l-30.636-52.13v62.043h-7.911V693.2h6.8l33.859,57.822,33.534-57.822Z"
          transform="translate(115.042 0.545)"
          fill="currentColor"
        />
        <path
          id="Path_34"
          data-name="Path 34"
          d="M-1382.438,769.611q-7.96-2.792-12.306-7.358l3.228-6.349a30.8,30.8,0,0,0,11.254,6.854,41.2,41.2,0,0,0,14.483,2.621q10.467,0,15.7-3.847a11.836,11.836,0,0,0,5.236-9.968,9.969,9.969,0,0,0-2.838-7.467,19.168,19.168,0,0,0-6.967-4.287,112.181,112.181,0,0,0-11.472-3.288,120.1,120.1,0,0,1-14.037-4.286,23.076,23.076,0,0,1-8.969-6.295q-3.728-4.241-3.728-11.476a19.419,19.419,0,0,1,3.12-10.749,21.321,21.321,0,0,1,9.578-7.738q6.463-2.906,16.04-2.9a47.385,47.385,0,0,1,13.093,1.84,37.405,37.405,0,0,1,11.081,5.073l-2.783,6.571a38.367,38.367,0,0,0-10.473-4.851,39.123,39.123,0,0,0-10.917-1.612q-10.248,0-15.427,3.956a12.277,12.277,0,0,0-5.182,10.19,10.148,10.148,0,0,0,2.843,7.516,18.5,18.5,0,0,0,7.13,4.346q4.29,1.514,11.531,3.288a116.056,116.056,0,0,1,13.87,4.178,22.774,22.774,0,0,1,8.964,6.24q3.679,4.176,3.679,11.308a19.216,19.216,0,0,1-3.174,10.749,21.376,21.376,0,0,1-9.751,7.684q-6.569,2.841-16.148,2.844A50.143,50.143,0,0,1-1382.438,769.611Z"
          transform="translate(192.372 0)"
          fill="currentColor"
        />
        <path
          id="Path_35"
          data-name="Path 35"
          d="M-1381.5,693.2h8.243v77.98h-8.243Z"
          transform="translate(250.979 0.545)"
          fill="currentColor"
        />
        <path
          id="Path_36"
          data-name="Path 36"
          d="M-1361.008,712.551l-34.533,77.98h-8.129l-34.538-77.98h8.916l29.855,67.73,30.077-67.73Z"
          transform="translate(0 86.209)"
          fill="currentColor"
        />
        <path
          id="Path_37"
          data-name="Path 37"
          d="M-1421.516,712.551h8.243v77.98h-8.243Z"
          transform="translate(73.878 86.209)"
          fill="currentColor"
        />
        <path
          id="Path_38"
          data-name="Path 38"
          d="M-1416.7,712.551h8.242V783.4h43.67v7.13H-1416.7Z"
          transform="translate(95.19 86.209)"
          fill="currentColor"
        />
        <path
          id="Path_39"
          data-name="Path 39"
          d="M-1405.372,712.551h8.242V783.4h43.67v7.13h-51.912Z"
          transform="translate(145.335 86.209)"
          fill="currentColor"
        />
        <path
          id="Path_40"
          data-name="Path 40"
          d="M-1385.611,790.531h-8.578l35.644-77.98h8.134l35.65,77.98h-8.693l-31.081-69.4Z"
          transform="translate(194.829 86.209)"
          fill="currentColor"
        />
        <path
          id="Path_41"
          data-name="Path 41"
          d="M-1317.725,752.087h7.906v29.747a38.1,38.1,0,0,1-12.92,7.352,48.146,48.146,0,0,1-15.709,2.561,43.086,43.086,0,0,1-21.053-5.128,37.8,37.8,0,0,1-14.705-14.2,39.294,39.294,0,0,1-5.345-20.332,39.289,39.289,0,0,1,5.345-20.327,37.817,37.817,0,0,1,14.705-14.206,43.379,43.379,0,0,1,21.168-5.128,45.728,45.728,0,0,1,16.376,2.843,32.7,32.7,0,0,1,12.589,8.411l-5.128,5.236q-9.246-9.132-23.507-9.132a35.023,35.023,0,0,0-17.1,4.173,30.737,30.737,0,0,0-11.976,11.585,32.106,32.106,0,0,0-4.34,16.544,31.781,31.781,0,0,0,4.34,16.485,31.406,31.406,0,0,0,11.922,11.59,34.319,34.319,0,0,0,17.044,4.233q12.029,0,20.386-6.018Z"
          transform="translate(259.619 85.661)"
          fill="currentColor"
        />
        <path
          id="Path_42"
          data-name="Path 42"
          d="M-1308.893,783.4v7.13h-55.147v-77.98h53.475v7.13h-45.227v27.734h40.328v7.021h-40.328V783.4Z"
          transform="translate(328.278 86.209)"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
