import styled, { css } from 'styled-components'
import avatarImg from './avatar.jpg'

export const ContactAvatarStyled = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: ${theme.spacing(3)};

    .avatar {
      ${theme.mixins.circle(theme.spacing(15))}

      background: url(${avatarImg});
      background-size: 100% auto;
      background-position: center 15%;
    }

    .contacts {
      text-align: left;

      .text-block {
        display: block;
      }
    }
  `
)
